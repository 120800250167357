import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import css from './style.module.scss'
import { Avatar, Logo, QuestionCircleOutlined, AddDependentInput, Button, Checkbox, Container, MasterPage, QuotesForm, TextInput, Select, WomanOutlined, ManOutlined, SelectOption, UserAddOutlined, UsergroupAddOutlined, HomeOutlined, EnvironmentOutlined, UserOutlined } from "../../components";
import MobileBackground from "../../assets/home-m-bg.svg";
import AboutPopup from "../../components/quotes-panel/components/profile/decision-support/popups/about-popup"


import _ from "lodash";

import {
  SettingOutlined, EditOutlined, EllipsisOutlined
} from "../../components"
import { Button as AntdButton, Card, Popover } from "antd"




const IndexPage = (props) => {
  const data = useStaticQuery(graphql`query assets2 {
        site {
          siteMetadata {
            agent {
                id,
                assets {
                    image
                },
                imageSrc,
                name,
                phone,
                whatsapp,
                email,
                website,
                title,
                calendly,
                bio
            }
          }
        }
      }
    `)
  const [aboutPopupVisible, setAboutPopupVisible] = useState(false);

  const openAboutPopup = () => setAboutPopupVisible(true);
  const closeAboutPopup = () => setAboutPopupVisible(false);



  return (
    <Container className={css.homeContainer}>
      <AboutPopup data={data} visible={aboutPopupVisible} onClose={closeAboutPopup} />
      {/* <Helmet>
          <meta viewport="width=device-width, initial-scale=1, minimum-scale=1,maximum-scale=1, shrink-to-fit=no"/>
        </Helmet> */}
      {/* //<SEO title="Home" /> */}
      <Container className={css.agentDataContainer}>
        <Container className={css.agentDataContainerContent}>
          <Container className={css.logoContainer}>
            <Container className={css.logo}>
              <svg>
                <use xlinkHref="./sprite.svg#apollo" />
              </svg>
            </Container>
            <Container className={css.logoText}>
              <span>Apollo </span> Quotes

            </Container>
          </Container>
          <Container className={css.agentData}>
            <Container className={css.avatarContainer}>
              <Avatar className={css.avatar} link="" round="true" src={data?.site?.siteMetadata?.agent?.imageSrc} />

            </Container>

            <Container className={css.buttonsAndTexts}>
              <Container className={css.agentName}>
                {data.site.siteMetadata.agent.name}
              </Container>
              <Container className={css.agentTitle}>
                {data.site.siteMetadata.agent.title}
              </Container>
              <Container onPress={() => { openAboutPopup() }} className={css.bioButton}>
                {data.site.siteMetadata.agent.name.split(" ")[0]}'s Bio
              </Container>
              <Container>
                <Container className={css.agentInfo}>

                  <svg className={css.phone}>
                    <use xlinkHref="/sprite.svg#phone-outlined" />
                  </svg>

                  <span>
                    {data.site.siteMetadata.agent.phone}
                  </span>
                </Container>
                {data?.site?.siteMetadata?.agent?.calendly && <a target="_blank" href={data?.site?.siteMetadata?.agent?.calendly}>
                  <Container className={css.agentInfo}>
                    <svg>
                      <use xlinkHref="/sprite.svg#schedule-call" />
                    </svg>
                    <span>
                      Schedule a Call
                        </span>
                  </Container>
                </a>}
                {false && <a target="_blank" href={"https://m.me/ApolloInsuranceAdvisors"}>

                  <Container className={css.agentInfo}>
                    <svg>
                      <use xlinkHref="/sprite.svg#facebook" />
                    </svg>
                    <span>
                      Send Message
                  </span>
                  </Container>
                </a>}
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className={css.agentDataContainerMobile}>
        <Container className={css.mobileBg}>

          <Container className={css.mobileAgentData}>
            <Container className={css.mobileLogoContainer}>
              <Logo />
            </Container>


            <Container className={css.avatarContainer}>
              <Avatar className={css.avatar} link="" round="true" src={data?.site?.siteMetadata?.agent?.imageSrc} />

            </Container>

            <Container className={css.buttonsAndTexts}>
              <Container>
                <Container className={css.agentName}>
                  {data.site.siteMetadata.agent.name}
                </Container>
                <Container className={css.agentTitle}>
                  {data.site.siteMetadata.agent.title}
                </Container>

                <Container className={css.agentInfoContainer}>
                  <Container className={css.agentInfo}>

                    <svg className={css.phone}>
                      <use xlinkHref="/sprite.svg#phone-outlined" />
                    </svg>

                    <span>
                      {data.site.siteMetadata.agent.phone}
                    </span>
                  </Container>
                  {data?.site?.siteMetadata?.agent?.calendly && <a target="_blank" href={data?.site?.siteMetadata?.agent?.calendly}>
                    <Container className={css.agentInfo}>
                      <svg>
                        <use xlinkHref="/sprite.svg#schedule-call" />
                      </svg>
                      <span>
                        Schedule a Call
                        </span>
                    </Container>
                  </a>}
                  {false && <a target="_blank" href={"https://m.me/ApolloInsuranceAdvisors"}>

                    <Container className={css.agentInfo}>
                      <svg>
                        <use xlinkHref="/sprite.svg#facebook" />
                      </svg>
                      <span>
                        Send Message
                  </span>
                    </Container>
                  </a>}
                </Container>
              </Container>
              <Container onPress={() => { openAboutPopup() }} className={css.bioButton}>
                {data.site.siteMetadata.agent.name.split(" ")[0]}'s Bio
              </Container>
            </Container>

          </Container>

        </Container>

      </Container>
      <Container className={css.mainContainer}>
        <Container className={css.mainContainerInner}>
          <Container className={css.headingsContainer}>
            <h1 className={`h1 ${css.healthInsurance}`}> Health insurance</h1>
            <h1 className={`h1 ${css.quotes}`}> Quotes and</h1>
            <h1 className={`h1 ${css.plans}`}> Plans</h1>
          </Container>
          <Container className={`${css.contentContainer}`}>

            <QuotesForm />

            <Container className={`${css.disclaimer}`}>
              <span>Disclaimer</span>
              These quotes are only estimates. Your actual premium, deductible, and coverage may be different. The quotes offered on this platform are not a promise of insurance, and your eligibility can only be determined by a qualified agent. Only your qualified agent can determine your insurability, plan rates, and health coverage. All quotes are subject to change.
            </Container>
          </Container>

        </Container >
      </Container>
    </Container >
  )
}

export default IndexPage
